.accordion {
  @include border-radius;
  border: $border;
  margin-bottom: 1rem;
  // margin-top: 1rem;

  // & + & {
  //   border-top: 1px solid $white;
  // }

  dt {
    padding: 0;
    width: 100%;
  }
  dd {
    padding: 0;
  }
}

button.accordion__trigger {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  width: 100%;
  // background: $grey-10;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: normal;
  transition: all 0.15s ease-in-out;
  color: $black;
  font-family: $body-font-family;
  border: none;
  border-radius: 0.25rem 0.25rem 0 0;

  &[aria-expanded="true"] {
    background: $black;
    color: $white;
    .accordion__title--secondary {
      color: $grey-light;
    }
  }
}

.accordion__title {
  display: inline-block;
  margin-bottom: 0;
  text-align: left;
  font-size: 1rem;
  border: 0;
}

.accordion__subtitle {
  // @include border-radius;
  flex-shrink: 0;
  padding: 0 4px;
  min-width: 1.25rem;
  height: 1.25rem;

  line-height: 1.25rem;
  background: $alert;
  color: $white;
  font-weight: 700;
  text-align: center;
  letter-spacing: -1px;
  @include border-radius;
}

.accordion__panel {
  margin: 0;
  height: auto;
  overflow: hidden;
  max-height: auto;
  // background: $white;
  // border: 1px solid $grey-50;
  // border-top: 0;
  transition: max-height 0.25s ease-in-out;
  // padding-top: 1rem;
}

.accordion__panel[aria-hidden="true"] {
  max-height: 0;
  // border-color: $white;
}

.animateIn {
  animation: accordionIn 0.25s normal ease-in-out both;
}

@keyframes accordionIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes accordionOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.accordion__section {
  margin-bottom: 1.5rem;
}

.accordion__inner {
  .accordion {
    border: none;
    margin: 0;
    border-bottom: $border;
    border-radius: 0;

    &:last-child {
      border-bottom: 0;
    }

    .accordion__trigger {
      margin: 0;
      // padding-left: 4rem;
      &[aria-expanded="true"] {
        background: $white;
        color: $black;
        .accordion__title--secondary {
          color: $grey;
        }
      }
    }
  }

  .accordion-container__lvl-3 {
    .accordion {
      background: $grey-10;
      @include border-radius;
      margin-bottom: 1rem;
    }

    .accordion__trigger {
      border: 0;
      padding-left: 3rem;
      &[aria-expanded="true"] {
        background: transparent;
      }
    }

    // .accordion__panel {
    //   background: green;
    // }

    .accordion__inner {
      padding: 0 2rem 1rem 5.5rem;
    }
  }
}

// ACCORDION (ERRORS SCREEN)

.accordion__top-lvl-error {
  padding: 1rem 2rem 1.5rem;
  border-bottom: $border;
}

.accordion__error__member-details {
  margin: 0 1.5rem 1rem;
  padding: 1rem;
  background-color: $grey-10;
  @include border-radius;
  p {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}

.accordion__title--primary {
  font-weight: bold !important;
}
.accordion__title--secondary {
  font-weight: normal;
  font-size: $font-small;
  color: $grey;
}

.field-name {
  word-break: break-all;
}
