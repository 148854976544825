.stp-summary {
  padding-bottom: 3.75rem;
  padding-top: 1rem;

  tbody {
    background: #fff;
  }
}

.filters {
  display: flex;
  align-items: center;
}

.filter-button {
  display: inline-flex;
  align-items: center;
  background: $white;
  padding: 0.5rem 1rem;
  border: 1px solid $grey-50;
  border-radius: 1.25rem;
  color: inherit;

  svg {
    margin-left: 0.5rem;
  }
}

.table-appendix {
  @include border-radius;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 1.5rem;
  border: $border;
  border-top: 0;

  select {
    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }
}

.uploaded-file {
  @include border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border: 1px solid $grey-30;

  .file-name {
    font-size: 15px;
    word-break: break-word;
  }

  .file-size {
    display: block;
    width: 80px;
    color: $grey;
  }

  .icon-tick {
    background-image: url("../../assets/images/icons/bs-check-circle-fill-green.svg");
    background-repeat: no-repeat;
    background-position: center;
    min-width: 1.5rem;
    height: 1.5rem;
  }
}

.table--general {
  width: 100%;
  //margin-bottom: 1rem;
  tr {
    &:last-child {
      td {
        border: none;
      }
    }
  }
  td {
    vertical-align: top;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid $grey-50;
    &:first-child {
      font-weight: bold;
      width: 200px;
    }
    .reminder {
      background-color: $grey-30;
      padding: 0.5rem 1rem;
      font-size: 14px;
      margin-top: 0.5rem;
      @include border-radius;
    }
  }
}
