.slide-pane__overlay--custom {
  z-index: 1;
}

.slide-pane--custom {
  max-width: 28rem;
}

.slide-pane--custom {
  .slide-pane__header {
    flex-direction: row-reverse;
    background: none;
    margin: 0 1rem;
    border-bottom: 0;
  }

  .slide-pane__close {
    margin: 0;
    opacity: 1;
    padding: 0;

    svg {
      width: 2rem;
      height: 2rem;
      color: $grey;
    }
  }

  .slide-pane__title-wrapper {
    margin-left: 0;
  }

  .slide-pane__title {
    @extend .h2;
  }

  .slide-pane__content {
    padding: 0;
  }

  .accordion__panel {
    border: 0;
  }
}
