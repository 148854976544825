.file-input {
  position: relative;
  // max-width: 30rem;
  margin: 1.25rem 0;
  padding: 3rem 1.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;

  .file-input__dragndrop,
  .file-input__icon {
    display: none;
  }

  &.has-advanced-upload {
    outline: 2px dashed $grey-50;
    outline-offset: -0.5rem;
    transition: background-color 0.2s ease-in-out;

    .file-input__icon {
      display: block;
    }

    .file-input__dragndrop {
      display: inline;
      text-decoration: underline;
      color: $link-color;
    }
  }

  &.is-dragover {
    outline-color: $blue;
    background-color: #fff;

    .file-input__icon {
      color: $blue;
    }
  }
}

.file-input__icon {
  display: block;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  display: block;
  margin: 0 auto 1.25rem;
  background: $turquoise;
  border-radius: 4rem;
  transition: color 0.2s ease-in-out;

  svg {
    width: 2rem;
    height: 2rem;
  }

  polyline,
  line {
    animation: float 1.5s ease-in-out infinite;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0);
  }
}

.file-input__input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.file-input__label {
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  .is-uploading & {
    display: none;
  }
}

.file-input__uploading {
  display: none;

  .is-uploading & {
    display: block;
  }
}
