.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #0e172d;
}

.footer__title {
  font-size: 1.75rem;
  font-weight: 500;
}

.footer__inner {
  max-width: 40rem;
  height: 100%;
  margin: 0 auto;
  padding: 1.25rem;
  text-align: center;
}

.footer__links {
  margin: 1.25rem 0;
  font-size: 0.75rem;

  ul li {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 1.25rem;

      &:after {
        content: "|";
        margin-left: 1.25rem;
      }
    }
  }

  a {
    color: $black;
  }
}

.footer__disclaimer {
  font-size: 0.75rem;
  font-weight: 400;
}
