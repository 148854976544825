.alert {
  @include box-shadow;
  width: 100%;
  background: tint($info, 90%);

  padding: 1rem 1rem 1.25rem;
  border: 1px solid $info;
  border-radius: $radius;
  margin-bottom: 1rem;

  @media (min-width: $bp-md) {
    aside & {
      max-width: 4rem;
    }
  }
}

.alert .alert__container {
  display: flex;

  .alert__icon {
    margin-right: 1rem;
    color: $alert;
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .alert__content {
    p:last-child {
      margin-bottom: 0;
    }
  }

  .alert__title {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .alert__message {
    color: $black;
    p {
      font-size: 15px;
    }

    small {
      color: $grey;
    }
  }
}

.alert--success {
  background: tint($success, 90%);
  border-color: $success;
  color: $success;
}

.alert--failure {
  background: tint($alert, 90%);
  border-color: $alert;
}

.alert--info {
  background: tint($info, 90%);
  border-color: $info;
}
