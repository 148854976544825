.user-dropdown {
  button {
    border: none;
    font-weight: 400;
    svg.button--icon-after__icon {
      width: 12px;
      height: 12px;
    }
  }

  .tippy-box {
    background: none;
    .tippy-content {
      padding: 0;
      background-color: $white;
      border: $border;
      border-radius: $radius;
      ul {
        hr {
          margin: 0;
        }

        li {
          span.static-menu-item {
            padding: 0.75rem 1rem;
            display: block;
            font-weight: bold;
          }

          button,
          a {
            padding: 0.75rem 1rem;
            font-size: 16px;
            font-family: $body-font-family;
            &:hover {
              background-color: $highlight;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
