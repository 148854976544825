.login__bg,
.login__container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  // background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
  //   url("./../../assets/images/PwC_Repre_Illus_RGB_02.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login__bg {
  width: 100%;
  height: 100%;
  position: fixed;
}

.login__container {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Logo {
    transform: scale(1.6);
    margin-bottom: 3rem;
  }
}
