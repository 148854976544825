$colors: (
  "success": #7eda92,
  "warning": #ff9b00,
  "pending": #ffde6b,
  "alert": #e11f30,
);

@each $status, $color in $colors {
  .is-#{$status} {
    color: $color !important;
  }
}
