@mixin border-radius($radius: 0.25rem) {
  border-radius: $radius;
}

@mixin box-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

@mixin image-size($size: 1rem) {
  width: $size;
  height: $size;
  background-size: $size;
}
