.contents {
  display: flex;
  flex-direction: column;
  border: $border;
  border-radius: $radius;

  .contents__item {
    border-bottom: $border;
    &:last-child {
      border-bottom: none;
    }
    a {
      display: block;
      padding: 1.2rem 2rem 1.2rem 4rem;
      background-image: url(./../../assets/images/icons/chevron-right.svg);
      background-repeat: no-repeat;
      background-position: right 1.5rem center;
      transition: 0.2s ease-in;

      &:hover {
        text-decoration: none;
        background-color: $grey-10;
        background-position: right 1rem center;
      }

      h2 {
        margin-bottom: 0.5rem;
        font-weight: 400;
        font-size: 18px;
      }
      p {
        margin-bottom: 0;
        color: $grey;
      }
    }

    background-repeat: no-repeat;
    background-position: 1.5rem center;

    //icons
    &--stp {
      background-image: url(./../../assets/images/icons/file-info.svg);
    }
    &--employer {
      background-image: url(./../../assets/images/icons/building.svg);
    }
    &--billing {
      background-image: url(./../../assets/images/icons/wallet2.svg);
    }
    &--users {
      background-image: url(./../../assets/images/icons/user-roles.svg);
    }
  }
}
