.timeline {
  li {
    display: flex;

    &:last-of-type .timeline-line:after {
      content: none;
    }
  }

  .timeline-line {
    position: relative;
    margin-right: 1rem;

    &:after {
      position: absolute;
      top: 2.5rem;
      left: 50%;
      content: "";
      width: 2px;
      height: calc(100% - 3rem);
      background-color: $grey-30;
      z-index: -1;
    }
  }

  .timeline-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;

    &.is-success {
      background-color: $success;
      svg {
        color: $black;
      }
    }

    &.is-pending {
      background-color: $pending;
      svg {
        color: $black;
      }
    }

    &.is-alert {
      background-color: $alert;
      svg {
        color: $white;
      }
    }

    &.is-cancelled {
      background-color: $grey-20;
      svg {
        color: $grey-80;
      }
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .timeline-details {
    margin-bottom: 2rem;

    p {
      margin-bottom: 0.2rem;
      max-width: 80ch;
    }
  }

  .h3 {
    margin-bottom: 0.5rem;
  }

  time {
    color: $grey;
    font-size: 14px;
  }

  .timeline-action {
    padding: 1rem;
    background-color: $grey-10;
  }
}

// ---------ACCORDION/EXPAND MESSAGES IN THE TIMELINE -----------//
.timeline .accordion {
  border: none;
  padding: 0;
  margin-bottom: 0.5rem;

  button.accordion__trigger {
    background: none;
    color: $link-color;
    padding: 0.5rem 0 1rem;
  }
  // .heading {
  //   background-color: none;
  //   .accordion__title div span {
  //     color: $link-color;
  //   }
  // }
  .accordion__inner {
    border: $border;
    // margin-top: 1rem;
    border-radius: $radius;
  }
  .title-bar {
    border-bottom: $border;
    padding: 1rem;
    color: $grey;
  }
  .message {
    padding: 1rem;
    border-top: $border;
    &:first-child {
      border-top: none;
    }
    &__short-description {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      margin-bottom: 0.8rem;
      h4 {
        margin-bottom: 0;
        margin-left: 0.5rem;
        font-family: $body-font-family;
        font-weight: bold;
      }
    }
    &__long-description {
      margin-top: 0.4rem;
      margin-bottom: 0.8rem;
    }
    &__extra-details {
      margin-bottom: 1rem;
      p {
        font-size: 14px;
        color: $grey;
        word-break: break-all;
        margin-bottom: 0.3rem;
      }
    }
    &__company-member {
      background-color: $grey-10;
      padding: 1rem;
      border-radius: $radius;
      display: flex;
      justify-content: space-between;
      .submission-id {
        color: $grey;
      }
    }
  }
  .resubmit {
    padding: 1rem;
    background-color: rgba($alert, 0.15);
    display: flex;
    align-items: center;
  }
}
