.stp-new {
  > section {
    margin-top: 1rem;
  }

  // Steppers
  .steppers-container {
    border-top: $border;
    border-bottom: $border;
    // Break out of .main container paddings
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-top: 2rem;
  }

  .section {
    padding: 2rem 0;
    background: #fff;

    h2 {
      margin-bottom: 1.5rem;

      & + p {
        max-width: 60ch;
        margin: 2.5rem auto 3rem;
      }
    }
  }

  .payment-details {
    @include border-radius;
    width: 30rem;
    padding: 2.5rem;
    border: $border;
  }

  // Selected file data summary
  .file-summary {
    max-width: 25rem;
    @include border-radius(1rem);
    background-color: $grey-10;
    // border: 1px solid $grey-30;

    h1 {
      font-size: 1rem;
      margin: 0;
      text-align: center;
      letter-spacing: -0.5px;
      // font-family: $body-font-family;
      // font-weight: bold;
      word-break: break-word;
    }

    > div {
      width: 100%;
      padding: 1rem 2rem;
      // border-bottom: 1px solid $grey-30;

      &:not(:last-of-type) {
        border-bottom: 1px solid $grey-30;
      }
    }

    .split {
      display: flex;
      padding: 0;

      div {
        padding: 1rem 2rem;
        flex: 1;
      }
      div:not(:last-of-type) {
        border-right: 1px solid $grey-30;
      }

      .number {
        font-family: $display-font-family;
        font-size: 1.5rem;
        display: block;
        font-weight: 500;
        margin-bottom: 4px;
      }
    }

    dt,
    dd {
      display: inline-block;
    }

    dt {
      width: 50%;
      font-size: 0.875rem;
      // color: $grey;
      // padding: 0.5rem 0;
      padding: 0;
    }

    dd {
      width: 50%;
      font-weight: bold;
      text-align: right;
      padding: 0.5rem 0;
      font-size: 0.875rem;
    }

    .total {
      dt,
      dd {
        font-weight: bold;
        font-size: 1rem;
        color: $black;
        padding: 0;
      }
    }
  }

  .alert--failure {
    margin-top: 1rem;
  }

  .validation-summary {
    @include border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid $alert;
    background-color: rgba(225, 31, 48, 0.1);

    .validation-summary__icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
      color: $alert;
    }

    .validation-summary__title {
      // font-size: 1.125rem;
      font-weight: 700;
    }

    p {
      margin-bottom: 0;
      font-size: 0.875rem;
    }
  }

  .document-list {
    padding-left: 2rem;
    list-style: disc;
  }

  .error-list {
    li {
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid $grey-30;
      }
    }
  }

  // Declaration
  .declaration {
    @include border-radius;
    max-width: 50rem;
    padding: 2.5rem;
    border: 1px solid $grey-50;

    ul {
      margin-left: 1.25rem;
      list-style: initial;
      line-height: 1.5rem;

      li + li {
        margin-top: 0.5rem;
      }
    }
  }

  .declaration__checkbox {
    @include border-radius;
    padding: 1rem;
    background: $grey-10;

    [type="checkbox"] {
      margin-right: 1rem;
    }
  }

  .declaration__signature {
    margin-top: 1.25rem;

    input[type="text"] {
      @include border-radius;
      border: 1px solid $grey-50;
      margin-left: 1rem;
    }
  }

  .tick {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background: $success;
    border-radius: 100%;
  }

  // Receipt
  .receipt-section {
    @include border-radius;
    @include box-shadow;
    max-width: 52rem;
    margin: 0 auto;
    padding: 2.5rem;
    border: 1px solid $grey-50;
    text-align: center;

    // hr {
    //   max-width: 25rem;
    // }
  }

  .error-controls {
    font-size: $font-small;
    button {
      padding: 1rem;
    }
  }
}
