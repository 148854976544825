.header {
  width: 100%;
  height: 64px;
  background: #fff;
  // border-bottom: 2px solid $grey-30;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.header__inner {
  height: 100%;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .back-link {
    a {
      display: flex;
      align-items: center;
      font-family: $display-font-family;

      svg {
        width: 24px;
        height: 24px;
      }
      &:hover {
        text-decoration-color: $black;
      }
    }
  }
}

.header__nav-left {
  display: flex;
  align-items: center;
}

.header__logo {
  position: relative;
  margin-right: 2rem;
  padding-right: 2rem;
}

.header__nav-right {
  span {
    display: none;

    @media (min-width: $bp-sm) {
      display: inline-block;
    }
  }

  .button {
    margin-left: 1.25rem;
  }
}
