// Base unit is .25rem, i.e. 4px
.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}
