.is-bold {
  font-weight: 700 !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.text-align-center {
  text-align: center;
}
