.button {
  padding: 1rem 1.5rem;
  background: transparent;
  border: 1px solid transparent;
  font-family: $display-font-family;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  transition: all 0.15s ease;

  &:focus,
  &:active {
    outline: 0;
    border-color: $grey;
    box-shadow: 0 0 0 1px $grey;
  }

  // &:hover {
  //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  // }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    border: none;
    &:hover {
      box-shadow: none;
      svg {
        transform: none;
      }
    }
  }
}

.button--primary {
  @include border-radius(2rem);
  // padding: 0.5rem 1.25rem;
  // padding: 1rem;
  background-color: $yellow;
  text-align: center;

  &:focus,
  &:hover,
  &:active {
    border-color: 0;
    background-color: tint($yellow, 10%);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.button--secondary {
  @include border-radius(2rem);
  padding: 0.5rem 1.25rem;
  background-color: $turquoise;
  text-align: center;

  &:focus,
  &:hover,
  &:active {
    border-color: 0;
    background-color: tint($turquoise, 10%);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.button--tertiary {
  color: $black;
  &:hover {
    text-decoration: underline;
  }
}

.button--success {
  padding: 0.5rem 1.25rem;
  background-color: $success;
  color: #fff;
  text-align: center;

  &:focus,
  &:hover,
  &:active {
    border-color: 0;
    background-color: tint($success, 10%);
  }
}

.button--outline {
  @include border-radius(2rem);
  // padding: 0.5rem 1.25rem;
  background-color: $white;
  border-color: $black;
  color: $black;
  text-align: center;

  &:focus,
  &:hover,
  &:active {
    border-color: shade($black, 10%);
    color: shade($black, 10%);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.button--outline-primary {
  @include border-radius(2rem);
  border-color: $yellow;
  color: $yellow;

  &:focus,
  &:hover,
  &:active {
    border-color: shade($yellow, 10%);
    color: shade($yellow, 10%);
  }
}

.button--outline-secondary {
  @include border-radius(2rem);
  border-color: $turquoise;
  color: $turquoise;

  &:focus,
  &:hover,
  &:active {
    border-color: shade($turquoise, 10%);
    color: shade($turquoise, 10%);
  }
}

.button--cta {
  border-radius: 1.25rem;
}

.button--sm {
  padding: 0.7rem 1.5rem;
  font-family: $body-font-family;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.button--icon {
  display: inline-flex;
  align-self: center;
  align-items: center;
  // padding-right: 2rem;
  background: none;
  border: 0;
  border-radius: 0;

  &:focus,
  &:hover,
  &:active {
    border: 0;
  }
}

.button--icon-lg {
  padding: 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.button--icon-before,
.button--icon-after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button--icon-before__icon,
.button--icon-afer__icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  color: currentColor;
}

.button--icon-before__icon {
  margin-right: 0.5rem;
}

.button--icon-after__icon {
  margin-left: 0.5rem;
}

.button--underline {
  font-weight: 400;
  text-align: left;
  border: 0;
  border-radius: 0;
  text-decoration: underline;

  &:focus,
  &:hover,
  &:active {
    color: $grey;
  }
}

.button--link {
  color: $link-color;
  font-weight: normal;
  text-decoration: underline;
}

.button--inline {
  display: inline;
  padding: 0;
}

.button--back,
.button--next {
  @include border-radius(2rem);
  position: relative;
  width: 12rem;
  padding: 1rem;

  svg {
    position: absolute;
    transition: transform 0.2s ease;
    width: 1.3rem;
    height: 1.3rem;
    margin-top: -2px;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.button--back {
  svg {
    left: 2rem;
  }

  &:focus,
  &:hover,
  &:active {
    svg {
      transform: translateX(-0.3rem);
    }
  }

  &.button--underline {
    padding-left: 1.5rem;
    width: auto;
    svg {
      left: 0;
    }
    &:hover {
      box-shadow: none;
    }
  }
}

.button--next {
  background-color: $yellow;
  border-color: $yellow;

  svg {
    right: 2rem;
  }

  &:focus,
  &:hover,
  &:active {
    border-color: shade($yellow, 10%);

    svg {
      transform: translateX(0.5rem);
    }
  }
}

.button-group {
  display: flex;

  border-top: $border;
  padding: 2rem 2.5rem 0;
  margin: 2rem -2.5rem 0;

  justify-content: space-between;

  .button + .button {
    margin-left: 1rem;
  }
}

.button-switch {
  @include border-radius(2rem);
  display: flex;
  justify-content: space-around;
  border: 1px solid $black;

  .button {
    flex: 1;
    // padding: 0.75rem 1.25rem;
    padding: 1rem 1.25rem;
    border-radius: 2rem 0 0 2rem;
    font-weight: normal;

    &.is-selected {
      background-color: rgba(162, 233, 220, 0.5);
    }
  }

  .button + .button {
    border-left: 1px solid $black;
    border-radius: 0 2rem 2rem 0;
  }
}

.back-container {
  margin-bottom: 0.5rem;
  button,
  a {
    display: flex;
    align-items: center;
    padding-top: 0;
    font-family: $body-font-family;
    font-size: 15px;
    text-decoration: none;
    color: $grey;
    &:hover {
      text-decoration: underline;
      text-decoration-color: $grey;
    }
  }
}

.button--full-width {
  width: 100%;
}

//used for text link
.button--exact-width {
  padding: 0;
}
