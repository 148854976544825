//list styles

//ordered-list alpha
ol.list-alpha {
  list-style-type: lower-alpha;
  margin-left: 1rem;
  padding-left: 0.5rem;
  line-height: 1.3rem;
  li {
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0.5rem;
  }
}
p ol {
  margin-top: 0.5rem;
}

//special styled ordered-list
.ordered-list {
  counter-reset: step;

  > li {
    counter-increment: step;
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 3.5rem;
    border-top: 1px solid $grey-30;

    &:before {
      content: counter(step);
      position: absolute;
      left: 0;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-color: $turquoise;
      text-align: center;
      line-height: 2rem;
      border-radius: 50%;
      color: $black;
      transition: all 0.2s ease-in-out;
    }

    h3 {
      line-height: 2rem;
    }

    ul {
      margin-left: 1rem;
      list-style: initial;

      li {
        margin-bottom: 1rem;
      }
    }
  }
}

//----- dl / dd / dt styles -------//

dl {
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    flex-direction: row;

    border-bottom: 1px solid $grey-20;
  }
  dt {
    font-size: 0.8rem;
    color: $grey;
    // font-weight: bold;
    width: 45%;
    text-align: right;
    padding: 1rem;
    line-height: 1rem;
  }
  dd {
    padding: 1rem;
  }
}
