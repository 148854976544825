.react-tabs {
  ul.react-tabs__tab-list {
    border-color: $grey-30;
    font-family: $display-font-family;
    margin-bottom: 2rem;
    li {
      padding: 1rem;
    }
    li.is-active {
      border: none;
      border-bottom: 5px solid $wrkr-yellow;
      font-weight: 500;
    }
    li.is-active::after {
      left: 0;
      right: 0;
      border-bottom: 5px solid $wrkr-yellow;
    }
  }
}
