//BRANDING
$wrkr-yellow: #f7de34;
$primary-color: $wrkr-yellow;

// COLORS
$blue: #2a69a4;
$blue-dark: #0759a4;
$red: #e11f30;
$orange: #f89828;
$yellow: $wrkr-yellow;
$turquoise: #a2e9dc;
$black: #333;
$grey: #5f6062;
$grey-light: #a1a0a4;
$grey-80: rgba(161, 160, 164, 0.8);
$grey-50: rgba(161, 160, 164, 0.5);
$grey-30: rgba(161, 160, 164, 0.3);
$grey-20: rgba(161, 160, 164, 0.2);
$grey-15: rgba(161, 160, 164, 0.15);
$grey-10: rgba(161, 160, 164, 0.1);
$grey-05: rgba(161, 160, 164, 0.05);
$white: #fff;
$turquoise-20: rgba(162, 233, 220, 0.2);

// STATUS COLORS
$default: $grey-30;
$new: #0348b2;
$success: #7eda92;
$warning: $yellow;
$pending: $yellow;
$alert: #e11f30;
$info: $turquoise;

// BREAKPOINTS
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1600px;

// MISC
$link-color: $blue-dark;
$border: 1px solid $grey-30;
$highlight: $grey-10;

// BORDER-RADIUS
$radius: 0.25rem;

// SMALL FONT SIZE
$font-small: 0.875rem;
