// what is this pagination code for?? - CL

.pagination {
  margin: 2rem auto;
  text-align: center;

  > li {
    display: inline-block;
    vertical-align: middle;

    a {
      @include border-radius;
      position: relative;
      float: left;
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0.125rem;
      padding: 0.375rem 0.75rem;
      background: #fff;
      border: 1px solid $grey-50;
      text-decoration: none;
      color: $blue;
      transition: all 0.1s ease-in-out;

      &:hover {
        cursor: pointer;
      }
    }
  }

  // Hover state
  > li > a:hover {
    border-color: $blue;
    color: $blue;
  }

  // Active state
  > .is-active > a {
    background: $blue;
    border-color: $blue;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  // Disabled state
  > .disabled > a,
  > .disabled > a:hover,
  > .disabled > a:focus {
    background: none;
    color: #777;
    cursor: not-allowed;
  }

  // Prev, next
  > li:first-child > a,
  > li:last-child > a {
    border: 0;
    background: none;
    background-color: transparent;
  }

  .Break-me > span {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0.125rem;
    padding: 6px 12px;
  }
}

//------ table pagination styles -------//

.pagination-table {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;

  &__show-rows {
    display: flex;
    align-items: center;
  }

  div {
    // border: 1px solid green;
    // vertical-align: middle;
    margin-left: 1rem;
  }

  &__buttons {
    .btn-pagination {
      border: none;
      background: transparent;
      cursor: pointer;
      &:disabled {
        cursor: default;
      }
    }
  }
}
