.spinner {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 20px auto;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  display: inline-block;
  position: absolute;
  top: 0;
  width: 60%;
  height: 60%;
  background-color: $wrkr-yellow;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

/*---------- HORIZONTAL LOADER (eg. used in the pill for processing status ----------------*/

.loader-horizontal {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #999;
  box-shadow: 16px 0 #999, -16px 0 #999;
  position: relative;
  animation: flash 0.6s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #ddd;
    box-shadow: 16px 0 #ddd, -16px 0 #999;
  }
  50% {
    background-color: #999;
    box-shadow: 16px 0 #ddd, -16px 0 #ddd;
  }
  100% {
    background-color: #ddd;
    box-shadow: 16px 0 #999, -16px 0 #ddd;
  }
}
