// Override existing CSS classes from react-toastify
.Toastify__toast-container {
  width: 100%;
  max-width: 900px;
}

.Toastify__toast {
  border-radius: 2rem;
  font-size: 14px;
  padding: 1rem 1.5rem 1rem 3.5rem;
  font-weight: bold;
  border: 1px solid $black;
  background-color: #fff;
  color: $black;
}

.Toastify__toast--error {
  // border-left: 0.5rem solid $alert;
  background-image: url("../../assets/images/icons/bs-exclamation-circle-fill-red.svg");
  background-repeat: no-repeat;
  background-position: 1.5rem center;
}

.Toastify__toast--success {
  // border-left: 0.5rem solid $success;
  background-image: url("../../assets/images/icons/bs-check-circle-fill-green.svg");
  background-repeat: no-repeat;
  background-position: 1.5rem center;
}

.Toastify__toast--info {
  border-left: 0.5rem solid #797d94;
}

.Toastify__close-button {
  align-self: auto;
  color: $grey;
}
