.error-section {
  width: 100%;
  height: auto;
  padding: 4rem 2rem;
  background: $grey-10;
  text-align: center;

  h2 {
    margin-bottom: 2rem;
  }

  p {
    max-width: 80ch;
    margin: 1rem auto 2rem;
  }
}
