.pill,
.badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: #fff;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0;
  color: $black;
  background: $grey-20;
  // min-width: 8rem;
  text-align: center;
  font-family: $body-font-family;
}

.pill--default,
.badge-info {
  background: $default;
}

.pill--new {
  background: $new;
  color: #fff;
}

.pill--pending {
  background: $pending;
}

.pill--success {
  background: $success;
}

.pill--warning,
.badge--warning {
  background: $warning;
}

.pill--alert,
.badge--error {
  background: $alert;
  color: #fff;
}

.pill--cancelled {
  background: $grey-20;
  color: $grey-light;
}

.pill--loader {
  .loader-horizontal {
    display: inline-block;
  }
}
.badge {
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
}
