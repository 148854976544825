.table-wrapper {
  border: $border;
  @include border-radius;
  @include box-shadow;
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
  line-height: 1;
  // border: $border;

  @media (min-width: $bp-lg) {
    table-layout: fixed;
  }

  tbody {
    tr {
      transition: background-color ease-out 0.5s;
      &:hover {
        background-color: $turquoise-20;
      }
    }
  }

  tr {
    border-bottom: $border;
  }

  th {
    padding: 1.25rem 1rem;
    vertical-align: middle;
    text-align: left;
    color: $grey;

    &.is-alignRight {
      text-align: right;
    }
  }

  td {
    padding: 1rem;

    a {
      color: $link-color;
    }

    &.is-capitalized {
      text-transform: capitalize;
    }

    &.is-alignRight {
      text-align: right;
    }

    small {
      display: block;
      margin-top: 0.25rem;
      font-weight: 300;
      line-height: 1;
      color: $grey;
      word-break: break-all;
    }
  }

  .col-sm {
    width: 80px;
  }

  .col-lg {
    width: 160px;
    @media (min-width: $bp-xxl) {
      width: 190px;
    }
  }

  .pill {
    width: 100%;
  }

  .isDisabled {
    cursor: not-allowed;
    color: $grey-80;
    display: inline-block;
    pointer-events: none;

    &:hover {
      text-decoration: none;
    }
  }
}

//SPECIFICALLY STP REPORTS TABLE
.table-stp {
  tr {
    td {
      padding: 0.8rem;
      &:nth-child(2) {
        word-break: break-all;
      }
    }
    td,
    th {
      border-left: 1px solid $grey-15;
      &:first-child {
        border-left: none;
      }
      &:nth-child(3) {
        text-align: center;
        width: 90px;
      }
      &:nth-child(4),
      &:nth-child(5) {
        text-align: right;
      }
      // &:nth-child(6),
      // &:nth-child(7),
      // &:nth-child(8) {
      //   width: 120px;
      // }
    }
  }
}

//TOP BAR WHICH INCLUDES FILTERS, SEARCH, REFRESH
.table-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: space-between;
  justify-content: flex-end; //for only one refresh button
  padding: 0.75rem;
  border-bottom: $border;
  // border: $border;
  // border-radius: 0.5rem 0.5rem 0 0;
  &__group {
    // border-left: $border;
    padding-left: 0.5rem;
  }
  button {
    border-radius: 4px;
    &:hover {
      background-color: $grey-10;
    }
  }
}

// .table-bar {
//   border-bottom: $border;

//   padding: 0.5rem 1rem;
//   display: flex;
//   justify-content: flex-end;
//   // &__button {
//   //   display: flex;
//   //   align-items: center;
//   //
//   //   padding: 1rem 2rem;

//   //   span {
//   //     margin-left: 0.5rem;
//   //   }
//   // }
// }
