.account-setup__header {
  text-align: center;
  .logo svg {
    height: 40px;
    width: 166px;
  }
}

.account-setup > div {
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;

  & + div {
    margin-top: 2rem;
  }
}

.account-setup__section {
  @include border-radius;
  max-width: 40rem;
  padding: 2.5rem;
  border: 1px solid $grey-50;
  background-color: $white;

  .scroll-box {
    @include border-radius;
    padding: 1rem;
    border: 1px solid $grey-50;
    overflow: auto;
    height: 24rem;
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 14px;
    p {
      font-size: 14px;
    }
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
    }
  }
}

.ssid {
  @include border-radius;
  padding: 1rem;
  background-color: $grey-10;
}
