.modal {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  padding: 1.5rem;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: auto;
  background: $white;
  border-radius: 3px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  overflow: auto;
  outline: none;
  text-align: left;
  word-break: break-word;
  z-index: 2;

  ul {
    list-style-type: disc;
    margin-left: 1rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
}

.modal--center {
  padding: 1.5rem 2.5rem;
  text-align: center;
}

.modal--action {
  padding: 0;

  .modal__body {
    padding: 1.5rem;
  }

  .modal__footer {
    padding: 1.5rem;
    border-top: 1px solid $border;

    .button {
      margin-bottom: 0;
    }
  }
}

.modal--lg {
  width: 800px;
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.4);

  small {
    color: #797d94;
  }
}
